<template>
  <!-- 授权产品 -->
  <div>
    <div class="zhanwei"></div>
    <el-form
      ref="form"
      :model="productData"
      :rules="rules"
      label-width="150px"
      label-position="right"
      style="width: 500px; margin: auto">
      <el-form-item
        :class="{paddRight: index === 0}"
        v-for="(item, index) in formProductItems" :key="index" :label="index === 0 ? t('product.add_product') : ''"
        :prop="item.prop" required>
        <el-select filterable clearable v-model="productData[item.prop]" :placeholder="t('form.selectPlaceholder')">
          <el-option v-for="(ele, key) in productList" :key="ele.value" :label="ele.label" :value="ele.value">
          </el-option>
        </el-select>
        <span v-if="index > 0" style="margin-left: 10px;cursor: pointer;" @click="delProduct(item.prop, index)">
          <el-icon>
            <Delete />
          </el-icon>
        </span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="addProduct">{{ t('form.add') }}</el-button>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="emit('prevStep')" type="primary">{{ t('form.prev') }}</el-button>
      <el-button @click="nextStep" type="primary">
        {{ !!!$userRole(['GeneralAdmin']) ? t('form.submit') : t('form.next') }}
      </el-button>
      <el-button @click="cancel">{{ t('form.cancel') }}</el-button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, getCurrentInstance, watch } from 'vue'
import { getSalesProduct } from '@/utils/common'
import store from '@/store'

const t = inject('t')
const { proxy } = getCurrentInstance()
const emit = defineEmits(['prevStep', 'onClose', 'nextStep'])
const productList = ref([])
const form = ref()
const props = defineProps({
  winShow: {
    type: Boolean,
    default: false
  }
})

// watch(() => props.winShow, val => {
//   if(!!!val) 
//   form.value.resetFields()
// }, { immediate: true, deep: false })

const formProductItems = ref([
  {
    prop: 'product_id0'
  }
]);
const productData = ref({
  product_id0: ''
})
const rules = ref({
  product_id0: [{ required: true, message: t('form.emptyErrMsg'), trigger: "change" }]
})
// 继续添加产品
const addProduct = () => {
  const newItem = { prop: `product_id${formProductItems.value.length}` }
  formProductItems.value.push(newItem);
  // 更新校验规则
  rules.value[newItem.prop] = [{ required: true, message: t('form.emptyErrMsg'), trigger: "change" }];
}

// 删除产品
const delProduct = (item, index) => {
  delete productData.value[item]
  formProductItems.value.splice(index, 1)
}

// 下一步
const nextStep = () => {
  form.value.validate(async valid => {
    if (valid) {
      emit('nextStep', {
        type: 2,
        data: Array.from(new Set(Object.values(productData.value)))
      })
    }
  })
}

// 重制表单，清空校验
const resteForm = () => {
  form.value.resetFields()
}

// 取消
const cancel = () => {
  resteForm()
  emit('onClose')
}

// 获取销售产品列表
const salesProduct = async (user_id) => {
  const res = await getSalesProduct(user_id)
  productList.value = res;
}

defineExpose({
  salesProduct: salesProduct,
  resteForm: resteForm
})

onMounted(async () => {
  if (proxy.$userRole(['SalesMan'])) {
    salesProduct(store.state.users.id)
  }
})
</script>

<style lang="scss" scoped>
.paddRight {
  padding-right: 24px !important;
}
</style>