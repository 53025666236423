<template>
  <div class="margin-t2">
    <!-- 创建企业用户表单 -->
    <el-form
      :model="formData.form"
      :rules="formData.rules"
      label-width="150px"
      label-position="right"
      ref="form">
      <!-- 所属销售 -->
      <el-form-item
       v-if="!!!$userRole(['SalesMan'])"
        :label="t('user.sales')"
        prop="sales_user_id">
        <el-select
          v-model="formData.form.sales_user_id"
          :disabled="disabledSales"
          :placeholder="t('form.inputPlaceholder')"
          filterable>
          <el-option
            v-for="(item, index) in salesList"
            :key="index"
            :label="item.label"
            :value="item.value" />
        </el-select>
      </el-form-item>
      <!-- 企业名称 -->
      <el-form-item :label="t('enterprise.name')" prop="name">
        <div class="form-item">
          <el-input :placeholder="t('form.inputPlaceholder')" v-model="formData.form.name" />
          <div class="tips">*{{ t('enterprise.full_name') }}</div>
        </div>
      </el-form-item>
      <!-- 统一社会代码 -->
      <el-form-item v-if="language !== 'en'" :label="t('enterprise.social_code')" prop="social_code">
        <div class="form-item">
          <el-input :placeholder="t('form.inputPlaceholder')" :disabled="!!edit_id" v-model="formData.form.social_code" />
          <div class="tips">*{{ t('enterprise.social_code_tip') }}</div>
        </div>
      </el-form-item>
      <!-- 企业编码 -->
      <el-form-item :label="t('enterprise.code')" prop="code">
        <el-input
          :placeholder="!$userRole(['GeneralAdmin']) ? t('enterprise.code_tip') : t('form.inputPlaceholder')"
          :disabled="disabledCode"
          v-model="formData.form.code" />
      </el-form-item>
      <!-- 企业联系人姓名 -->
      <el-form-item :label="t('user.enterprise_Liaison')" prop="liaison_man">
        <el-input :placeholder="t('form.inputPlaceholder')" v-model="formData.form.liaison_man" />
      </el-form-item>
      <!-- 企业联系人手机号 -->
      <el-form-item :label="t('user.enterprise_liaison_phone')" prop="phone">
        <el-input :placeholder="t('form.inputPlaceholder')" type="number" v-model="formData.form.phone" />
      </el-form-item>
      <!-- 企业联系人邮箱 -->
      <el-form-item :label="t('user.enterprise_liaison_mail')" prop="mail">
        <el-input :placeholder="t('form.inputPlaceholder')" v-model="formData.form.mail" />
      </el-form-item>
      <!-- 企业类型 -->
      <el-form-item :label="t('enterprise.type')" prop="label">
        <el-select v-model="formData.form.label" :placeholder="t('form.selectPlaceholder')">
          <el-option :label="t('enterprise.general')" value="0" />
          <el-option :label="t('enterprise.testing')" value="1" />
          <el-option :label="t('enterprise.scan')" value="2" />
        </el-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="onSubmit" type="primary">{{ !!edit_id ? t('form.submit') : t('form.next') }}</el-button>
      <el-button @click="cancel">{{ t('form.cancel') }}</el-button>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, computed, inject } from 'vue'
import { enterpriseManagement } from '@/utils/api'
import store from '@/store'
import { getUserList } from '@/utils/common'

const language = ref(localStorage.getItem('language') && localStorage.getItem('language').toLowerCase())
const old_name = ref()
const old_code = ref()
const t = inject('t')

const props = defineProps({
  edit_id: {
    type: [String, Number],
    default: ''
  },
  winShow: {
    type: Boolean,
    default: false
  }
})

const { proxy } = getCurrentInstance()
const form = ref()
const salesList = ref([])
const emit = defineEmits(['nextStep', 'onClose'])
// 是否禁用所属销售
const disabledSales = computed(() => {
  return !!props.edit_id
})
// 非运营下禁用编码
const disabledCode = computed(() => {
  if (proxy.$userRole(['GeneralAdmin'])) {
    return !!props.edit_id
  }
  return true
})
const formData = ref({
  form: {
    label: '0'
  },
  rules: {
    sales_user_id: [{ required: true, message: t('form.emptyErrMsg') }],
    name: [{
      required: true,
      validator: (rule, value, callback) => {
        if (!!!value) {
          callback(new Error(t('form.emptyErrMsg')))
        } else {
          let checkData = {
            type: 'name',
            name: value
          }
          if (old_name.value) {
            checkData.original = old_name.value
          }
          enterpriseManagement.checkEnterprise(checkData).then(res => {
            if (res.success) {
              callback()
            } else {
              callback(new Error(res.reason))
            }
          })
        }
      }
    }],
    code: [
      { min: 2, max: 20, message: t('enterprise.code_length'), trigger: 'blur' },
      { pattern: /^[a-zA-Z-]+$/, message: t('enterprise.code_type'), trigger: 'blur' },
      {
        required: proxy.$userRole(['GeneralAdmin']),
        trigger: ['blur', 'change'],
        validator: (rule, value, callback) => {
          // 非运营下不校验
          if (!!!proxy.$userRole(['GeneralAdmin'])) {
            callback()
          } else {
            if (!!!value) {
              callback(new Error(t('form.emptyErrMsg')))
            } else {
              let checkData = {
                type: 'code',
                code: value
              }
              if (old_code.value) {
                checkData.original = old_code.value
              }
              enterpriseManagement.checkEnterprise(checkData).then(res => {
                if (res.success) {
                  callback()
                } else {
                  callback(new Error(res.reason))
                }
              })
            }
          }
        }
      }
    ],
    social_code: [{ required: true, message: t('form.emptyErrMsg') }],
    liaison_man: [{ required: true, message: t('form.emptyErrMsg') }],
    phone: [{ required: true, message: t('form.emptyErrMsg') }],
    mail: [
      { required: true, message: t('form.emptyErrMsg') },
      { type: 'email', message: t('form.error_mail') }
    ],
    label: [{
      required: true,
      validator: (rule, value, callback) => {
        if (!!!value) {
          callback(new Error(t('form.emptyErrMsg')))
        } else if (value !== '0') {
          enterpriseManagement.EnterpriseList({
            filter_label: value
          }).then(res => {
            if (res.total > 0) {
              callback(new Error(t('enterprise.type_tip')))
            } else {
              callback()
            }
          })
        } else {
          callback()
        }
      }
    }]
  }
})
//编辑绑定数据
const bindData = (data) => {
  let keyList = []
  for (let key in formData.value.rules) {
    keyList.push(key)
  }
  for (let i in data) {
    if (keyList.includes(i))
      formData.value.form[i] = data[i]
  }
  formData.value.form = {
    ...formData.value.form,
    label: data.label.toString(),
    liaison_man: data.params.liaison_man,
    mail: data.params.mail,
    comment: data.params.comment ? data.params.comment : '',
    phone: data.params.phone
  }
  old_code.value = data.code
  old_name.value = data.name
}

// 下一步
const onSubmit = () => {
  if (!form.value) return
  form.value.validate(vaild => {
    if (!!!vaild) return
    let subData = {
      ...formData.value.form,
      status: proxy.$userRole(['GeneralAdmin']) ? 1 : '0',
      params: {
        comment: formData.value.comment,
        liaison_man: formData.value.form.liaison_man,
        mail: formData.value.form.mail,
        phone: formData.value.form.phone
      }
    }
    delete subData.liaison_mail
    delete subData.comment
    delete subData.mail
    delete subData.phone
    emit('nextStep', {
      type: 1,
      data: subData
    })
  })
}

onMounted(async () => {
  // 获取销售列表
  if (proxy.$userRole(['SalesMan'])) {
    formData.value.form.sales_user_id = store.state.users.id
  } else {
    salesList.value = await getUserList({ filter_role_alias: 'SalesMan' })
  }
})

// 重制表单，清空校验
const resteForm = () => {
  form.value.resetFields()
}

defineExpose({
  bindData: bindData,
  resteForm: resteForm
})

// 取消
const cancel = () => {
  resteForm()
  emit('onClose')
}

</script>
